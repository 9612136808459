import React, { FunctionComponent } from 'react';
import { Container, Typography } from '@material-ui/core';

const About: FunctionComponent = () => {
    return (
        <Container>
            <Typography>
                <b>Multistream.co</b> is the best site for watching multiple streams at the same time. 
                You can watch YouTube videos, Twitch streams with chats, Twitter feeds and many more. 
                You can create your own streams layout: do you want to have fullscreen video with smaller streams inside? 
                No problem, you can do that with Multistream.co!
            </Typography>
            <br />
            <Typography>
                Selected features of Multistream.co:
            </Typography>
            <ul>
                <li><Typography>Embed YouTube videos, Twitch streams and chats, Twitter feeds, most websites and raw video and audio urls</Typography></li>
                <li><Typography>Customize your own streams layout: you can drag and resize all added streams</Typography></li>
                <li><Typography>When dragging you can snap streams to each other, to screen edges or you can merge them to one and use tabs to navigate</Typography></li>
                <li><Typography>Dynamically change streams layout</Typography></li>
                <li><Typography>Share created Multistreams with your friends and viewers</Typography></li>
                <li><Typography>Use Multistream for collaborations with other streamers - your viewers can watch all streams at once</Typography></li>
                <li><Typography>Using Multistream doesn't have negative impact on your views - they are still counted as on original site</Typography></li>
                <li><Typography>Synchronize multiple streams or videos</Typography></li>
                <li><Typography>Watch multiple game streams - focus only on interesting fragments</Typography></li>
                <li><Typography>Option to unmute streams on mouse hover</Typography></li>
                <li><Typography>Responsive design for all screen sizes</Typography></li>
            </ul>
        </Container>
    );
};

export default About;