export const createMultistreamUrl = (key: string, title: string): string => {
    return createUrl('p', key, title);
}

export const createGameUrl = (id: string, name: string): string => {
    return createUrl('game', id, name);
}

const createUrl = (route: string, key: string, title: string): string => {
    const titleUri = title.replace(/[^\w'-.À-ÿ]+/g, '_');
    const url = `/${route}/${key}/${titleUri}`;
    return url;
}