import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';

const cardWidth = 315;

const styles = () => createStyles({
    card: {
        minWidth: cardWidth,
        maxWidth: cardWidth
    }
});

export type CardStream = {
    title?: string;
    url?: string;
    thumbnailUrl?: string;
};

type MultistreamCardRootProps = WithStyles<typeof styles> & {
    title: string;
    description?: string;
    streams: Array<CardStream>;
};

const MultistreamCardRoot: FunctionComponent<MultistreamCardRootProps> = (props) => {
    const { classes, children } = props;
    const { streams, title, description } = props;

    const tooltip = getTooltip(title, description, streams);

    return (
        <Card className={classes.card} raised title={tooltip}>
            {children}
        </Card>
    );
}

function getTooltip(title: string, description: string, streams: Array<CardStream>): string {
    var tooltip = title + '\n';

    if (description) {
        tooltip += description + '\n';
    }

    if (streams) {
        streams.forEach(stream => {
            if (stream) {
                if (stream.title) {
                    tooltip += stream.title + ': ';
                }

                if (stream.url) {
                    tooltip += stream.url;
                }

                tooltip += '\n';
            }
        });
    }

    return tooltip;
}

export default withStyles(styles)(MultistreamCardRoot);