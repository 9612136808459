import React, { FunctionComponent } from 'react';
import { Container, Typography } from '@material-ui/core';

const CookiesPolicy: FunctionComponent = () => {
    return (
        <Container>
            <Typography variant='h4'>Cookies</Typography>
            <br />
            <Typography>To make this site work properly, we sometimes place small data files called cookies on your device. Most big websites do this too.</Typography>
            <br />
            <Typography variant='h5'>What are cookies?</Typography>
            <br />
            <Typography>A cookie is a small text file that a website saves on your computer or mobile device when you visit the site. It enables the website to remember your actions and preferences (such as login, language, font size and other display preferences) over a period of time, so you don’t have to keep re-entering them whenever you come back to the site or browse from one page to another.</Typography>
            <br />
            <Typography variant='h5'>How do we use cookies?</Typography>
            <br />
            <Typography>A number of our pages use cookies to:</Typography>
            <ul>
                <li><Typography>to identify the user</Typography></li>
                <li><Typography>gather statistics</Typography></li>
                <li><Typography>remember some actions performed by the users</Typography></li>
                <li><Typography>user settings</Typography></li>
            </ul>
            <Typography>
                Also, some embedded websites and videos in multistreams use a cookies to anonymously gather statistics on how you got there and what websites you visited.
                Enabling these cookies is not strictly necessary for the website to work but it will provide you with a better browsing experience. You can delete or block these cookies, but if you do that some features of this site may not work as intended.
                The cookie-related information is not used to identify you personally and the pattern data is fully under our control. These cookies are not used for any purpose other than those described here.
            </Typography>
            <br />
            <Typography variant='h5'>How to control cookies</Typography>
            <br />
            <Typography>You can control and/or delete cookies as you wish – for details, see aboutcookies.org. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.</Typography>
        </Container>
    );
};

export default CookiesPolicy;