import React, { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const footerMenuOptions = [
    { label: 'contact', link: '/contact' },
    { label: 'cookies', link: '/cookies' },
    { label: 'privacy', link: '/privacy' }
];

type FooterProps = {
    version: string;
};

const Footer: FunctionComponent<FooterProps> = (props) => {
    const { version } = props;
    const currentYear = new Date().getFullYear();

    return (
        <Container>
            <br />
            <hr />
            <footer>
                <Box m={2}>
                    <Typography>
                        kampar
                        &copy; 2016-{currentYear} | v{version}
                        {footerMenuOptions.map(menuOption => (
                            <span key={menuOption.label}>&nbsp;|&nbsp;<Link component={RouterLink} to={menuOption.link}>{menuOption.label}</Link></span>
                        ))}
                    </Typography>
                </Box>
            </footer>
        </Container>
    )
}

export default Footer;