import React, { FunctionComponent } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';


const User: FunctionComponent = () => {
    const { isAuthenticated, user, logout } = useAuth0();

    if(!isAuthenticated) {
        return (
            <Box display="flex" justifyContent='center'>
                <Typography variant='h5'>
                    <b>You need to login to access this page.</b>
                </Typography>
            </Box>
        );
    }

    return (
        <Container maxWidth='md'>
            <Typography variant='h4' align='center'>
                <b>User info</b>
            </Typography>

            <Box display="flex" flexDirection="column" my={4}>
                <Box display="flex" justifyContent='center'>
                    <Typography variant='body1'>
                        Name: {user.name}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent='center'>
                    <Typography variant='body1'>
                        Nickname: {user.nickname}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent='center'>
                    <Typography variant='body1'>
                        Email: {user.email}
                    </Typography>
                </Box>

                <Box display="flex" justifyContent='center' my={4}>
                    <Button variant='contained' color='primary' onClick={() => logout()}>
                        Logout
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export default User;