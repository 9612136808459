import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import CardMedia from '@material-ui/core/CardMedia';

const cardWidth = 315;

const styles = () => createStyles({
    thumbnail: {
        height: cardWidth * 9 / 16
    }
});

export type CardStream = {
    title?: string;
    url?: string;
    thumbnailUrl?: string;
};

type StreamThumbnail = {
    url: string;
    title: string;
};

type MultistreamCardMediaProps = WithStyles<typeof styles> & {
    streams: Array<CardStream>;
};

const MultistreamCardMedia: FunctionComponent<MultistreamCardMediaProps> = (props) => {
    const { classes } = props;
    const { streams } = props;

    const streamThumbnail = getStreamThumbnails(streams)[0].url;

    return (
        <CardMedia className={classes.thumbnail} image={streamThumbnail} />
    );
}

function getStreamThumbnails(streams: Array<CardStream>): Array<StreamThumbnail> {
    var thumbnails: Array<StreamThumbnail>;

    if (streams) {
        thumbnails = streams.filter((stream) => {
            return stream && stream.thumbnailUrl;
        }).map((stream) => {
            return {
                url: stream.thumbnailUrl,
                title: stream.title
            };
        });
    }

    if (!thumbnails || thumbnails.length === 0) {
        return [{ url: '/images/defaultMultistream.png', title: '' }];
    }

    return thumbnails;
}

export default withStyles(styles)(MultistreamCardMedia);