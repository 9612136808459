import React, { FunctionComponent } from 'react';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './LoginButton';
import SearchBar from './SearchBar';

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1
    },
    appBar: {
        backgroundColor: '#292b2c'
    },
    title: {
        flexGrow: 1
    },
    menuOptions: {
        marginTop: 4,
        marginLeft: theme.spacing(4),
        flexGrow: 1
    },
    menuIcon: {
        marginRight: theme.spacing(2)
    },
    menuItem: {
        padding: theme.spacing(2, 1.3)
    },
    menuButton: {
        color: 'rgba(255,255,255,.5)',
        '&:hover': {
            color: 'rgba(255,255,255,.8)'
        }
    },
    activeMenuButton: {
        color: 'rgba(255,255,255)!important'
    },
    drawerList: {
        width: 250
    }
});

type MenuOptionType = {
    label: string;
    link: string;
    isPrivate?: boolean;
};

type MenuProps = WithStyles<typeof styles> & {
    websiteName: string;
    menuOptions: Array<MenuOptionType>;
};

const Menu: FunctionComponent<MenuProps> = (props) => {
    const { classes } = props;
    const scrollTrigger = useScrollTrigger();

    return (
        <div className={classes.root}>
            <Slide appear={false} direction='down' in={!scrollTrigger}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Hidden smDown>
                            <DesktopMenuContent {...props} />
                        </Hidden>
                        <Hidden mdUp>
                            <MobileMenuContent {...props} />
                        </Hidden>

                        <SearchBar />
                        <LoginButton />
                    </Toolbar>
                </AppBar>
            </Slide>
        </div>
    );
};

const DesktopMenuContent: FunctionComponent<MenuProps> = (props) => {
    const { classes } = props;
    const { websiteName, menuOptions } = props;
    const { isAuthenticated } = useAuth0();

    return (
        <React.Fragment>
            <Hidden mdDown>
                <Typography variant='h5'>
                    <Link component={RouterLink} to='/' color='inherit' underline='none'>
                        {websiteName}
                    </Link>
                </Typography>
            </Hidden>

            <div className={classes.menuOptions}>
                <Typography variant='h6' color="textSecondary" noWrap>
                    {menuOptions.filter(x => isAuthenticated || !x.isPrivate).map(menuOption => (
                        <Link key={menuOption.label} component={NavLink} exact to={menuOption.link}
                            className={`${classes.menuItem} ${classes.menuButton}`} activeClassName={classes.activeMenuButton} color="inherit" underline='none'>
                            {menuOption.label}
                        </Link>
                    ))}
                </Typography>
            </div>

        </React.Fragment>
    );
};

const MobileMenuContent: FunctionComponent<MenuProps> = (props) => {
    const { classes } = props;
    const { websiteName, menuOptions } = props;
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const { isAuthenticated } = useAuth0();

    function handleDrawerOpen() {
        setOpenDrawer(true);
    }

    function handleDrawerClose() {
        setOpenDrawer(false);
    }

    return (
        <React.Fragment>
            <IconButton onClick={handleDrawerOpen} edge='start' className={classes.menuIcon} color='inherit' aria-label='Menu'>
                <MenuIcon />
            </IconButton>
            <Hidden xsDown>
                <Typography variant='h6' className={classes.title}>
                    <Link component={RouterLink} to='/' color='inherit' underline='none'>
                        {websiteName}
                    </Link>
                </Typography>
            </Hidden>

            <Drawer open={openDrawer} onClose={handleDrawerClose}>
                <div className={classes.drawerList} role="presentation" onClick={handleDrawerClose} onKeyDown={handleDrawerClose}>
                    <List>
                        {menuOptions.filter(x => isAuthenticated || !x.isPrivate).map(menuOption => (
                            <Link key={menuOption.label} component={RouterLink} to={menuOption.link} color='inherit' underline='none'>
                                <ListItem button>
                                    <ListItemText primary={menuOption.label} />
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                </div>
            </Drawer>
        </React.Fragment>
    );
};

export default withStyles(styles)(Menu);