import React, { FunctionComponent } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';

const BrowseTabs: FunctionComponent = (props) => {
    const location = useLocation();
    const currentTab = location.pathname;

    return (
        <Container>
            <Box display='flex' justifyContent='center'>
                <Paper>
                    <Tabs value={currentTab} indicatorColor='primary' textColor='primary'>
                        <Tab value='/browse' icon={<ViewModuleIcon />} label='Latest' component={RouterLink} to='/browse' />
                        <Tab value='/games' icon={<VideogameAssetIcon />} label='Games' component={RouterLink} to='/games' />
                    </Tabs>
                </Paper>
            </Box>
        </Container>
    );
}

export default BrowseTabs;
